/**
 * Dichiarazione che definisce il tipo window customizzato
 * per supportare i dati specificati nelle configurazioni
 * presenti in /resources
 */
declare global {
    interface Window {
        runConfig: RdapConfig;
    }
}

type RdapConfig = {
    REACT_APP_RDAP_SERVICE : string
}


/**
 * Funzione per recuperare la configurazione dell'app
 */
function getWindowRunConfig() {
    return {...window['runConfig']};
}


console.log("REACT_APP_RDAP_SERVICE",getWindowRunConfig())
/**
 * COSTANTI ottenute tramite configurazione
 */
export const RDAP_SERVICE_URL =
    getWindowRunConfig().REACT_APP_RDAP_SERVICE